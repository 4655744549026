<template>
  <v-container class="mx-auto">
    <div class="section-title">
      <h1 class="primary--text">
        {{ $t("homepage.categoryTitle") }}
      </h1>
      <h3 class="secondary--text">
        {{ $t("homepage.categorySubTitle") }}
      </h3>
    </div>
    <div
      class="d-flex justify-end align-center"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <div>
        <v-select
          :items="sort"
          item-text="text"
          item-value="value"
          :value="sortFilter"
          label="Ordina per"
          @change="handleSortFilter"
        ></v-select>
      </div>
    </div>
    <div
      class="d-flex justify-space-between align-center primary lighten-2 mx-n3"
      v-else
    >
      <div class="pl-3">{{ $t("sort") }}</div>
      <v-btn-toggle
        :value="sortFilter"
        group
        color="primary"
        @change="handleSortFilter"
      >
        <v-btn class="round" value="alf_asc">
          <v-icon>mdi-sort-alphabetical-ascending</v-icon>
        </v-btn>

        <v-btn class="round" value="alf_dsc">
          <v-icon>mdi-sort-alphabetical-descending</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col
        v-for="category in categories"
        :key="category.id"
        class="d-flex justify-center"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <CategoryCard :category="category" />
      </v-col>
    </v-row>
    <v-list v-else>
      <v-list-item
        v-for="category in categories"
        :key="category.id"
        @click.prevent="handleLink(category.slug)"
      >
        <v-list-item-title>{{ category.name }}</v-list-item-title>
        <v-list-item-action>
          <v-icon color="primary">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
import CategoryCard from "./CategoryCard.vue";
import category from "~/mixins/category";
import { mapState, mapGetters } from "vuex";
export default {
  name: "CategoryCustomGrid",
  mixins: [category],
  components: { CategoryCard },
  data() {
    return {
      select: null,
      sort: [
        { value: "alf_asc", text: "A-Z" },
        { value: "alf_dsc", text: "Z-A" }
      ]
    };
  },
  methods: {
    handleSortFilter(sort) {
      this.$store.dispatch("category/filterCategoryTree", sort);
    },
    handleLink(url) {
      if (url) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: url }
        });
      }
    }
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    ...mapGetters({
      sortFilter: "category/sortCategoryFilter"
    })
  },
  metaInfo() {
    return {
      title: this.$t("navbar.navigation.browse")
    };
  }
};
</script>
<style scoped>
.round {
  border-radius: 50% !important;
}
</style>
